/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'

import { useNavigate } from 'react-router-dom';


const Dashboard = () => {
    const history = useNavigate();
    const Logout = (e) => {
        e.preventDefault();
        localStorage.clear('accessToken');

        localStorage.clear('refreshToken');

        localStorage.clear('fio');

        localStorage.clear('username');

        history('/');
    }

    if (localStorage.getItem('accessToken') == null) history("/");
    return (

        <section className="hero  is-fullheight is-fullwidth">

                <div className="container ">
                    <div className="mt-5">
                    <div className="columns ">
                        <div className="column" align="right">
                            <div className="username-top ">
                                {localStorage.getItem('fio').length>1?localStorage.getItem('fio'):localStorage.getItem('username')}
                            </div>
                            <a href="" className="button logout-button " onClick={Logout}>Выйти</a>
                        </div>
                        <div className="column is-1" align="right">
                            <img src="qsim_ico.png" className="image is-64x64" alt = "КвадроСим"/>
                        </div>
                    </div>
                    </div>

                    <div className="  menu is-fullwidth">
                        <div className="field mt-5 ">
                            <a href="/organizations" className="button login-button ">Организации</a>
                        </div>
                        <div className="field mt-5 ">
                            <a href="" className="button login-button ">Демо лицензии</a>
                        </div>
                    </div>
                </div>

        </section>
    )
}

export default Dashboard
