import * as React from 'react'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {render} from "@testing-library/react";
import jwt_decode from "jwt-decode";


const License = () => {



    const [searchParams, setSearchParams] = useSearchParams();

    const [keys, setKeys] = useState([]);
    const [login, setLogin] = useState('');
    const [pwd, setPwd] = useState('');
    const [client_license_type, setClient_license_type] = useState('edu');
    const [license_type, setLicense_type] = useState('hybrid');

    const [msg, setMsg] = useState('');


    const history = useNavigate();

    if (localStorage.getItem('accessToken') == null) history("/");

    useEffect(() => {
        refreshToken();
        getLicense()
    }, []);

    const refreshToken = async () => {

        try {

            //alert()
            await axios.post('https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/refresh/', { refresh: localStorage.getItem('refreshToken')})

                .then(response => {
                    //alert(response);
                    const newAccessToken = response.data.access;

                    const newRefreshToken = response.data.refresh;

                    localStorage.setItem('accessToken', newAccessToken)

                    localStorage.setItem('refreshToken', newRefreshToken)



                })
        } catch (error) {
            console.log(error);
            if (error.status=== 401) alert(error.data.detail);
            if (error.response) {
                history("/");
            }
        }
    }


    const getLicense = async () => {
        const currentDate = new Date();
        if (localStorage.getItem('expire') < currentDate) await refreshToken();

        const response = await axios.get('https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/licenses/' + searchParams.get("id") + '/', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                },


            }
        )
            .then(response => {



                setLicense_type(response.data.license_type);
                setClient_license_type(response.data.client_license_type);
                setLogin(response.data.login);
                setPwd(response.data.password);

                setKeys(response.data.keys);

            })
            .catch(error => {
                console.log(error);
                if (error.code === "ERR_BAD_REQUEST") {


                }
            });


    }


    const Send = ()=>{

        axios.post('https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/licenses/'+searchParams.get("id") + '/update_license/', {


                client_license_type:client_license_type,
                login:login,
                password:pwd,

        },
            {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            })

            .then(response => {

                if (response.status !== 200) return
                console.log(response);


                window.location.reload();
            })

            .catch(error => {
                console.error(error);
                if (error.response) {
                    setMsg(error.response.data.msg);
                }
            })
    }
    return (
        <section className="hero  is-fullheight is-fullwidth">
            <div className="container mt-5 is-fullwidth">
                <h1>{searchParams.get("name")+'/'+login}</h1>
                <div className="info-table-container">
                    <p className="has-text-centered">{msg}</p>
                    <table className="table  is-fullwidth">
                        <thead>
                        <tr>
                            <th>Тип лицензии</th>
                            <th>Логин</th>
                            <th>Пароль</th>


                            <th></th>

                        </tr>
                        </thead>
                        <tbody>

                        <tr>

                            <td><select id="cl_type" value={client_license_type}
                                        onChange={(e) => setClient_license_type(e.target.value)}>
                                <option value="edu">edu</option>
                                <option value="sport">sport</option>
                                <option value="world">world</option>
                                <option value="pro">pro</option>
                                <option value="vr">vr</option>
                                <option value="competition">competition</option>

                            </select>
                            </td>
                            <td><input type="label" id="name" value={login}
                                       onChange={(e) => setLogin(e.target.value)}/>
                            </td>
                            <td><input type="label" id="pwd" value={pwd} onChange={(e) => setPwd(e.target.value)}/>

                            </td>

                            <td><a onClick={Send} className="button login-button ">Изменить</a></td>
                        </tr>

                        </tbody>
                    </table>

                </div>
                <div className="table-container">
                    <table className="table  is-fullwidth">
                        <thead>
                        <tr>
                            <th>Номер ключа</th>
                            <th>Мак адресс</th>
                            <th>Активирован</th>

                            <th>Срок истечения</th>
                            <th>Удаление/продление</th>


                        </tr>
                        </thead>
                        <tbody>
                        {keys.map(o => (

                            <tr>
                                <td>  {o.id}</td>
                                <td>  {o.mac_address}</td>
                                <td>  {o.validated ? 'Активирован' : 'Не активирован'}</td>

                                <td>  {new Date(o.expires_at).toLocaleDateString('ru-Ru')}</td>
                                <td><a href={"/key?id=" + o.id + "&l_id=" + searchParams.get("id")}
                                       className="button login-button ">Открыть</a></td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
                <a href={"/add_keys?l_id=" + searchParams.get("id")} className="button login-button ">Добавить ключи</a>
                <a href={"/longate_license?l_id=" + searchParams.get("id")} className="button login-button "
                   id="add_org">Продлить ключи</a>
            </div>
        </section>
    )


}

export default License;