import * as React from 'react'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {render} from "@testing-library/react";
import jwt_decode from "jwt-decode";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";



const AddKeys = () => {



    const [searchParams, setSearchParams] = useSearchParams();
    const [msg, setMsg] = useState('');
    const [organization, setOrganization] = useState([]);
    const history = useNavigate();
    const [expDate, setExpDate] = useState(new Date());
    const [startDate, setStartDate] = useState(new Date());
    const [amount, setAmount] = useState('');


    if (localStorage.getItem('accessToken') == null) history("/");

    useEffect(() => {
        refreshToken();

    }, []);

    const refreshToken = async () => {

        try {

            //alert()
            await axios.post('https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/refresh/', { refresh: localStorage.getItem('refreshToken')})

                .then(response => {
                    //alert(response);
                    const newAccessToken = response.data.access;

                    const newRefreshToken = response.data.refresh;

                    localStorage.setItem('accessToken', newAccessToken)

                    localStorage.setItem('refreshToken', newRefreshToken)



                })
        } catch (error) {
            if (error.status=== 401) alert(error.data.detail);
            console.log(error);
            if (error.response) {
                history("/");
            }
        }
    }


    const Send = ()=>{
        expDate.setHours(23,59,59)
        axios.patch('https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/licenses/'+searchParams.get("l_id")+'/add_keys/', {

                expires_at:expDate.toISOString(),
                number_of_keys: parseInt(amount),

                activate_from: startDate.toISOString()

            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            })

            .then(response => {

                if (response.status !== 200) return
                console.log(response);


                history("/license?id="+searchParams.get("l_id"));
            })

            .catch(error => {
                console.error(error);
                if (error.response) {
                    setMsg(error.response.data.msg);
                }
            })
    }
    return (
        <section className="hero  is-fullheight is-fullwidth">
            <div className="container mt-5 is-fullwidth">

                <p className="has-text-centered">{msg}</p>
                <table className="table  is-fullwidth" >
                    <thead>
                    <tr>

                        <th>Количество    </th>
                        <th>Дата активации</th>
                        <th>Дата истечения</th>




                    </tr>
                    </thead>
                    <tbody>
                    <tr>

                        <td>  <input type="number" id="amount"       value={amount}     onChange={(e) => setAmount(e.target.value)}/></td>
                        
                        <td><DatePicker selected={startDate} onChange={(date) => setStartDate(date)} dateFormat="dd/MM/YYYY"/></td>
                        <td> <DatePicker selected={expDate} onChange={(date) => setExpDate(date)} dateFormat="dd/MM/YYYY"/> </td>

                    </tr>
                    </tbody>
                </table>

                <a id="" onClick={Send} className="button login-button " >Добавить</a>
            </div>
        </section>
    )


}

export default AddKeys;
