import React from 'react'
import {useNavigate} from "react-router-dom";

const Navbar = () => {

    const history = useNavigate();
    const Logout = (e) => {
        e.preventDefault();
        localStorage.clear('accessToken');

        localStorage.clear('refreshToken');

        localStorage.clear('fio');

        localStorage.clear('username');

        history('/');
    }



    return (
        <div className="container ">
        <div className="mt-5">
            <div className="columns ">
                <div className="column" align="">
                    <a href="/dashboard" className="button login-button ">В главное меню</a>
                </div>
                <div className="column" align="right">
                    <div className="username-top ">
                        Информация о продажах
                    </div>
                    <a href="" className="button logout-button " onClick={Logout}>Выйти</a>
                </div>
                <div className="column is-1" align="right">
                    <img src="qsim_ico.png" className="image is-64x64" alt = "КвадроСим"/>
                </div>
            </div>
        </div>
        </div>
    )
}

export default Navbar
