import * as React from 'react'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {render} from "@testing-library/react";
import jwt_decode from "jwt-decode";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


const Key = () => {



    const [searchParams, setSearchParams] = useSearchParams();

    const [key, setKey] = useState([]);
    const [expDate, setExpDate] = useState(new Date());
    const [actDate, setActDate] = useState(new Date());


    const history = useNavigate();
    const [msg, setMsg] = useState('');


    if (localStorage.getItem('accessToken') == null) history("/");

    useEffect(() => {
        refreshToken();
        getKey();
    }, []);

    const refreshToken = async () => {

        try {

            //alert()
            await axios.post('https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/refresh/', { refresh: localStorage.getItem('refreshToken')})

                .then(response => {
                    //alert(response);
                    const newAccessToken = response.data.access;

                    const newRefreshToken = response.data.refresh;

                    localStorage.setItem('accessToken', newAccessToken)

                    localStorage.setItem('refreshToken', newRefreshToken)



                })
        } catch (error) {
            console.log(error)
            if (error.response.status=== 401) alert(error.response.data.detail);
            if (error.response) {
                history("/");
            }
        }
    }


    const getKey = async () => {
        const currentDate = new Date();
        if (localStorage.getItem('expire')<currentDate) await refreshToken();

        const response = await axios.get('https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/keys/'+searchParams.get("id")+'/', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                },


            }
        )
            .then( async (response)=>{

                //console.log(response);
                await setKey(await response.data);
                console.log(await response.data.expires_at+'key');
                setExpDate( new Date( await response.data.expires_at));
                setActDate( new Date( await response.data.activate_from));
            })
            //.then(   (response) =>{setExpDate( new Date( response.data.expires_at));})
            .catch(error =>{
                console.log(error);
                if (error.code === "ERR_BAD_REQUEST") {




                }
            });

        //var nd = key.expires_at.slice(0,10);
        //console.log(nd);




    }
    const Send = ()=>{
        expDate.setHours(23,59,59)
        axios.patch('https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/keys/'+key.id+'/', {
                expires_at:expDate.toISOString(),
                activate_from:actDate.toISOString()
            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            })

            .then(response => {

                if (response.status !== 200) return
                console.log(response);


                history("/license?id="+searchParams.get("l_id"));
            })

            .catch(error => {
                console.error(error);
                if (error.response) {
                    setMsg(error.response.data.msg);
                }
            })
    }
    const Delete = ()=>{
        axios.delete('https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/keys/'+key.id+'/',
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            })

            .then(response => {

                if (response.status !== 204) return
                console.log(response);


                history("/license?id="+searchParams.get("l_id"));
            })

            .catch(error => {
                console.error(error);
                if (error.response) {
                    setMsg(error.response.data.msg);
                }
            })

    }

    return (
        <section className="hero  is-fullheight is-fullwidth">
            <div className="container mt-5 is-fullwidth">

                <p className="has-text-centered">{msg}</p>
                <table className="table  is-fullwidth" >
                    <thead>
                    <tr>

                        <th>MAC</th>
                        <th>Активирован</th>
                        <th>Срок активации</th>
                        <th>Срок истечения</th>


                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td> {key.mac_address} </td>
                        <td> {key.validated ? 'активирован' : 'неактивирован'} </td>
                        <td><DatePicker selected={actDate} onChange={(date) => setActDate(date)}
                                        dateFormat="d/M/Y"/></td>
                        <td><DatePicker selected={expDate} onChange={(date) => setExpDate(date)}
                                        dateFormat="d/M/Y"/></td>

                    </tr>
                    </tbody>
                </table>
                <a id="delete" onClick={Delete} className="button login-button ">Удалить</a>
                <a id="add_org" onClick={Send} className="button login-button " >Продлить</a>
            </div>
        </section>
    )


}

export default Key;