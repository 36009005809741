import * as React from 'react'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {render} from "@testing-library/react";
import jwt_decode from "jwt-decode";


const AddOrganization = () => {



    const [searchParams, setSearchParams] = useSearchParams();


    const [login, setLogin] = useState('');
    const [client_license_type, setClient_license_type] = useState('edu');



    const [msg, setMsg] = useState('');


    const history = useNavigate();

    if (localStorage.getItem('accessToken') == null) history("/");

    useEffect(() => {
        refreshToken();

    }, []);

    const refreshToken = async () => {

        try {

            //alert()
            await axios.post('https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/refresh/', { refresh: localStorage.getItem('refreshToken')})

                .then(response => {
                    //alert(response);
                    const newAccessToken = response.data.access;

                    const newRefreshToken = response.data.refresh;

                    localStorage.setItem('accessToken', newAccessToken)

                    localStorage.setItem('refreshToken', newRefreshToken)



                })
        } catch (error) {
            console.log(error);
            if (error.status=== 401) alert(error.data.detail);
            if (error.response) {
                history("/");
            }
        }
    }


    const Send = ()=>{
        if(login.length<1 || client_license_type.length<1 ){
            setMsg('заполните все поля');
            return(0);
        }
        axios.post('https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/licenses/', {

            organization:searchParams.get("org_id"),
           login:login,
           client_license_type:client_license_type,


        },
            {
                headers: {
                        Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            })

            .then(response => {

                if (response.status !== 201) return
                console.log(response);


                history("/organization?id="+searchParams.get("org_id"));
            })

            .catch(error => {
                console.error(error);
                if (error.response) {
                    setMsg(error.response.data.msg);
                }
            })
    }
    return (
        <section className="hero  is-fullheight is-fullwidth">
            <div className="container mt-5 is-fullwidth">

                <p className="has-text-centered">{msg}</p>
                <table className="table  is-fullwidth" >
                    <thead>
                    <tr>

                        <th>Логин</th>

                        <th>Тип лицензии</th>

                    </tr>
                    </thead>
                    <tbody>
                    <tr>

                        <td><input type="label" id="name" value={login} onChange={(e) => setLogin(e.target.value)}/>
                        </td>
                        <td><select id="cl_type" value={client_license_type} onChange={(e) => setClient_license_type(e.target.value)}>
                            <option value="edu">edu</option>
                            <option value="sport">sport</option>
                            <option value="world">world</option>
                            <option value="pro">pro</option>
                            <option value="vr">vr</option>
                            <option value="competition">competition</option>

                        </select>
                        </td>


                    </tr>
                    </tbody>
                </table>

                <a href={"/organization?id="+searchParams.get("org_id")} className="button login-button ">Отмена</a>
                <a id="add_org" onClick={Send} className="button login-button ">Добавить</a>

            </div>
        </section>
    )


}

export default AddOrganization;