import React, { useState } from 'react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';


const Login = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [msg, setMsg] = useState('');
    const history = useNavigate();

    const Auth = async (e) => {
        e.preventDefault();



        axios.post('https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/auth/', {
            username: username,
            password: password,
        })

            .then(response => {

                if (response.status !== 200) return
                console.log(response);
                localStorage.setItem('accessToken', response.data.access);

                localStorage.setItem('refreshToken', response.data.refresh);

                localStorage.setItem('fio', response.data.fio);

                localStorage.setItem('username', username);

                const currentDate = new Date();
                let expire = new Date();
                console.log(response.data.lifetime);
                const lifetime = response.data.lifetime;
                expire = currentDate.getTime()+ lifetime *60000;
                console.log(expire);
                localStorage.setItem('expire', expire.toString());
                console.log(currentDate.getTime());
                history("/dashboard");
            })

            .catch(error => {
                console.error(error);
                if (error.response) {
                    setMsg(error.response.data.msg);
                }
            })




    }

    return (

        <section className="hero  is-fullheight is-fullwidth">

            <div className="hero-body">
                <div className="container">
                    <div className="columns is-centered">
                        <div className="column is-4-desktop">

                            <form onSubmit={Auth} className="               ">
                                <p className="has-text-centered">{msg}</p>
                                <div className="field mt-5 has-text-centered">
                                    <img src="qsim_ico.png" className=" " alt="КвадроСим"/>
                                </div>
                                <div className="field mt-5">


                                    <div className="controls">
                                        <input type="text" className="input" placeholder="Username" value={username}
                                               onChange={(e) => setUsername(e.target.value)}/>
                                    </div>
                                </div>
                                <div className="field mt-5">

                                    <div className="controls">
                                        <input type="password" className="input" placeholder="Password" value={password}
                                               onChange={(e) => setPassword(e.target.value)}/>
                                    </div>
                                </div>
                                <div className="field mt-5 ">
                                    <button className="button login-button is-fullwidth">Enter</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Login
