import * as React from 'react'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {render} from "@testing-library/react";
import jwt_decode from "jwt-decode";


const Organizations = () => {




    const [organizations, setOrganizations] = useState([]);
    const history = useNavigate();

    if (localStorage.getItem('accessToken') == null) history("/");

    useEffect(() => {
        refreshToken();
        getOrganizations();
    }, []);
    //{"detail":"access_token expired"}
    const refreshToken = async () => {

        try {

            //alert()
            await axios.post('https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/refresh/', { refresh: localStorage.getItem('refreshToken')})

                .then(response => {
                    //alert(response);
                    const newAccessToken = response.data.access;

                    const newRefreshToken = response.data.refresh;

                    localStorage.setItem('accessToken', newAccessToken)

                    localStorage.setItem('refreshToken', newRefreshToken)



                })
        } catch (error) {
            console.log(error)
            if (error.response) {
                history("/");
            }
        }
    }


    const getOrganizations = async () => {
        const currentDate = new Date();
        if (localStorage.getItem('expire')<currentDate) await refreshToken();

        const response = await axios.get('https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/organizations/', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                },


            }
        )
            .then(response=>{

                console.log(response);
                setOrganizations(response.data);
                //console.log(response.data);
            })
            .catch(error =>{
                console.log(error);
                if (error.status=== 401) alert(error.data.detail);
                if (error.code === "ERR_BAD_REQUEST") {
//{"detail":"access_token expired"}    401



                }
            });




    }

    return (
        <section className="hero  is-fullheight is-fullwidth">
        <div className="container  mt-5 is-fullwidth">

            <div className="table-container">
            <table className="table  is-fullwidth" >
                <thead>
                <tr>
                    <th>Наименование организации</th>
                    <th>Количество лицензий</th>

                    <th>Общее количество ключей    </th>
                    <th>Количество активированных ключей</th>
                    <th>Закончившиеся ключи</th>
                    <th>Специалист отдела продаж</th>
                    <th>Открыть подробную информацию о организации</th>

                </tr>
                </thead>
                <tbody>
                    {organizations.map(o=>(

                        <tr>
                            <td>{o.name}</td>
                            <td>{o.licenses_count}</td>

                            <td>{o.keys_amount}</td>
                            <td>{o.keys_activated}</td>
                            <td>{o.keys_expired}</td>
                            <td>{o.sales_specialist_org}</td>
                            <td><a href={"/organization?id="+o.id} className="button login-button ">Открыть</a></td>
                        </tr>
                    ))}

                </tbody>
            </table>
            </div>
            <a href={"/add_organization"} className="button login-button ">Добавить организацию</a>
        </div>

        </section>
    )


}

export default Organizations;