import { Outlet, BrowserRouter, Route, Routes } from "react-router-dom";
import { useParams } from 'react-router-dom'
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import Navbar from "./components/Navbar";
import Register from "./components/Register";
import CreateProject from "./components/CreateProject";
import Organizations from "./components/Organizations";
import Organization from "./components/Organization";
import AddOrganization from "./components/AddOrganization";
import Key from "./components/Key";
import AddKeys from "./components/AddKeys";
import LongateLicense from "./components/LongateLicense";
import AddLicense from "./components/AddLicense";
import License from "./components/License";

function App() {
  return (

    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Login/>}/>

        <Route path="/register" element={<Register/>}/>
          <Route path="/dashboard" element={<Dashboard/> } />


        <Route path="/" element={<LayoutsWithNavbar />}>


            <Route path="/organizations" element={<Organizations/> } />
            <Route path="/organization" element={<Organization/> } />
            <Route path="/add_organization" element={<AddOrganization/> } />
            <Route path="/add_license" element={<AddLicense/> } />
            <Route path="/license" element={<License/> } />
            <Route path="/add_keys" element={<AddKeys/> } />

            <Route path="/key" element={<Key/> } />

            <Route path="/longate_license" element={<LongateLicense/> } />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
function LayoutsWithNavbar() {
    return (
        <>
            {/* Your navbar component */}
            <Navbar />

            {/* This Outlet is the place in which react-router will render your components that you need with the navbar */}
            <Outlet />

            {/* You can add a footer to get fancy in here :) */}
        </>
    );
}
export default App;
