import * as React from 'react'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {useEffect, useRef, useState} from "react";
import axios from "axios";
import {render} from "@testing-library/react";
import jwt_decode from "jwt-decode";


const Organization = () => {



    const [searchParams, setSearchParams] = useSearchParams();


    const [licenses, setLicenses] = useState([]);
    const history = useNavigate();


    const [name, setName] = useState('');
    const [inn, setInn] = useState('');
    const [contact, setContact] = useState('');
    const [phone, setPhone] = useState('');


    const [msg, setMsg] = useState('');


    if ((localStorage.getItem('accessToken') === null)||(localStorage.getItem('accessToken') === '')) history("/");

    useEffect(() => {
        refreshToken();
        getOrganization();
    }, []);

    const refreshToken = async () => {

        try {

            //alert()
            await axios.post('https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/refresh/', { refresh: localStorage.getItem('refreshToken')})

                .then(response => {
                    //alert(response);
                    const newAccessToken = response.data.access;

                    const newRefreshToken = response.data.refresh;

                    localStorage.setItem('accessToken', newAccessToken)

                    localStorage.setItem('refreshToken', newRefreshToken)



                })
        } catch (error) {
            console.log(error);
            if (error.status=== 401) alert(error.data.detail);
            if (error.response) {
                history("/");
            }
        }
    }


    const getOrganization = async () => {
        const currentDate = new Date();
        if (localStorage.getItem('expire') < currentDate) await refreshToken();

        const response = await axios.get('https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/organizations/' + searchParams.get("id") + '/', {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                },


            }
        )
            .then(response => {

                console.log(response.data);

                setName(response.data.name);
                setContact(response.data.email);
                setPhone(response.data.phone);
                setInn(response.data.inn)


                setLicenses(response.data.licenses);

            })
            .catch(error => {
                console.log(error);
                if (error.code === "ERR_BAD_REQUEST") {


                }
            });


    }
    const Send = ()=> {
        if (name.length < 1 || inn.length < 1 || contact.length < 1 || phone.length < 1) {
            setMsg('заполните все поля');
            return (0);
        }
        axios.post('https://xn--e1aebclo5dzd.xn--80adhqgqmpk.xn--p1ai/custom_admin/organizations/' + searchParams.get("id") + '/update_organization/', {
            name: name,
                inn: inn,
                email: contact,
                phone: phone,
            },
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('accessToken')}`
                }
            })
            .then(response => {
                if (response.status !== 200) return
                console.log(response);
                window.location.reload();
            })
            .catch(error => {
                console.error(error);
                if (error.response) {
                    setMsg(error.response.data.msg);
                }
            })
    }





    return (
        <section className="hero  is-fullheight is-fullwidth">
            <div className="container mt-5 is-fullwidth">
                <h1>{name}</h1>
                <div className="info-table-container">
                    <p className="has-text-centered">{msg}</p>
                    <table className="table  is-fullwidth">
                        <thead>
                        <tr>

                            <th>Наименование</th>
                            <th>ИНН</th>

                            <th>e-mail</th>
                            <th>Контактный телефон</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>

                            <td><input type="label" id="name" value={name} onChange={(e) => setName(e.target.value)}/>
                            </td>
                            <td><input type="label" id="inn" value={inn} onChange={(e) => setInn(e.target.value)}/></td>

                            <td><input type="label" id="contact" value={contact}
                                       onChange={(e) => setContact(e.target.value)}/></td>
                            <td><input type="label" id="contact" value={phone}
                                       onChange={(e) => setPhone(e.target.value)}/></td>
                            <td><a id="" onClick={Send} className="button login-button ">Сохранить</a></td>

                        </tr>
                        </tbody>
                    </table>

                </div>
                <div className="table-container">
                    <table className="table  is-fullwidth">
                        <thead>
                        <tr>
                            <th>Тип лицензии</th>
                            <th>Логин</th>
                            <th>Пароль</th>

                            <th>Общее количетсво ключей</th>
                            <th>Количество активированных ключей</th>
                            <th>Закончившиеся ключи</th>
                            <th></th>

                        </tr>
                        </thead>
                        <tbody>
                        {licenses.map(o => (

                            <tr>
                                <td>  {o.client_license_type}</td>
                                <td>  {o.login}</td>
                                <td>  {o.password}</td>

                                <td>  {o.keys_amount}</td>
                                <td>  {o.keys_activated}</td>
                                <td>  {o.keys_expired}</td>
                                <td><a href={"/license?id=" + o.id + "&org_id=" + searchParams.get("id") + "&name="+name}
                                       className="button login-button ">Открыть</a></td>
                            </tr>
                        ))}
                        </tbody>
                </table>
                </div>
                <a href={"/add_license?org_id="+searchParams.get("id")} className="button login-button ">Добавить лицензию</a>

            </div>
        </section>
    )


}

export default Organization;